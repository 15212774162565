import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from "@mui/material/Grid";
import contact1 from "../contactImg/marvin-hoareau.webp";
import contact2 from "../contactImg/isabelle-baldino.webp";

// Style
const useStyles = makeStyles(theme => ({
	colorEspelia:{
		color:'#424242',
		textAlign:'left',
		"& div" : {
			fontSize : 12
		},
	},
	image : {
		width : 220,
		height : "auto",
		borderRadius: "90%"
	},
}));

export default function Contact() {
  	const classes = useStyles();
	const theme = useTheme();
	const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('md')); 

	return (
		<Grid container direction="row" justifyContent="center" spacing={1}>
			<Grid item container direction="column" xs={12} sm={12} md={12} lg={6}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<img src={contact1} alt="Marvin HOAREAU" title="Marvin HOAREAU" className={classes.image} />
					<div style={{marginTop: "2vh"}}>
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 25, fontWeight: "bold", color: "#f7ad0d", margin: 0}}>Marvin HOAREAU</p>
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 18, fontWeight: "light", margin: 0}}>Chargé de développement commercial</p><br />
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 18, fontWeight: "bold", margin: 0,  marginBottom: "5vh"}}>
							<a href="mailto:marvin.hoareau@espelia.fr" style={{textDecoration: "none", color: "white"}}>marvin.hoareau@espelia.fr</a> 
							<br />
							<a style={{textDecoration:"none", color: "white"}} href="tel:0786210477">07 86 21 04 77</a>
						</p>
					</div>
				</Grid>
			</Grid>    
			<Grid item container direction="column" xs={12} sm={12} md={12} lg={6}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<img src={contact2} alt="Pierre DALMAYRAC" title="Pierre DALMAYRAC" className={classes.image} />
					<div style={{marginTop: "2vh"}}>
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 25, fontWeight: "bold", color: "#f7ad0d", margin: 0}}>Isabelle BALDINO</p>
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 18, fontWeight: "light", margin: 0}}>Directrice du Pôle Accompagnement RH </p><br />
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 18, fontWeight: "bold", margin: 0, marginBottom: "5vh"}}>
							<a href="mailto:isabelle.baldino@espelia.fr" style={{textDecoration: "none", color: "white"}}>isabelle.baldino@espelia.fr</a> 
							<br />
							<a style={{textDecoration:"none", color: "white"}} href="tel:0780950329">07 80 95 03 29 </a>
						</p>
					</div>
				</Grid> 
			</Grid>    
		</Grid>
	);
}
